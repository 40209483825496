import { FetchResult } from '@apollo/client'

import {
  CustomerAccessTokenCreateMutation,
  CustomerAccessTokenCreateMutationVariables,
  CustomerAccessTokenDeleteMutation,
  CustomerAccessTokenDeleteMutationVariables,
  CustomerCreateInput,
  CustomerCreateMutation,
  CustomerCreateMutationVariables,
  CustomerErrorCode,
  CustomerRecoverMutation,
  CustomerRecoverMutationVariables,
  GetCustomerOrdersQuery,
  GetCustomerOrdersQueryVariables,
} from '@data/shopify/storefront/types'
import {
  CUSTOMER_ACCESS_TOKEN_CREATE,
  CUSTOMER_ACCESS_TOKEN_DELETE,
  CUSTOMER_CREATE,
  CUSTOMER_RECOVER,
} from '@data/shopify/storefront/mutations/customer'
import { GET_CUSTOMER_ORDERS } from '@data/shopify/storefront/queries/customer'
import { PasswordRecoveryFormValues, UserCookie } from '@lib/auth'
import { USER_LIST_PAGE_LIMIT } from '@lib/user'
import {
  getGrqphQLErrorFieldName,
  parseMutationResult,
  ParseResults,
  ParseStatus,
  ShopifyClient,
} from './client'

/**
 * Gets customer creation validation results.
 */
const parseCustomerCreateResult = (
  customerCreateResult: FetchResult<CustomerCreateMutation>
): ParseResults => {
  return parseMutationResult(
    customerCreateResult.data?.customerCreate?.customerUserErrors ?? []
  )
}

/**
 * Gets customer login validation results.
 */
const parseCustomerAccessTokenCreateResult = (
  customerAccessTokenCreateResult: FetchResult<CustomerAccessTokenCreateMutation>
): ParseResults => {
  const customerUserErrors =
    customerAccessTokenCreateResult.data?.customerAccessTokenCreate
      ?.customerUserErrors ?? []
  const results: ParseResults = {
    fieldErrors: {},
    errorCount: customerUserErrors.length,
    status: ParseStatus.OK,
  }

  customerUserErrors.forEach((customerUserError) => {
    const fieldName = getGrqphQLErrorFieldName(customerUserError.field)

    if (fieldName) {
      results.fieldErrors[fieldName] = customerUserError.message
    }

    if (customerUserError.code === CustomerErrorCode.UnidentifiedCustomer) {
      results.status = ParseStatus.INVALID_CREDENTIALS
    }
  })

  return results
}

/**
 * Gets customer recovery validation results.
 */
const parseCustomerRecoverResult = (
  customerRecoverResult: FetchResult<CustomerRecoverMutation>
): ParseResults => {
  return parseMutationResult(
    customerRecoverResult.data?.customerRecover?.customerUserErrors ?? []
  )
}

/**
 * Creates a new Shopify user account.
 */
export const createUser = async (
  shopifyStorefrontClient: ShopifyClient,
  values: CustomerCreateInput
) => {
  try {
    const customerCreateResult = await shopifyStorefrontClient.mutate<
      CustomerCreateMutation,
      CustomerCreateMutationVariables
    >({
      mutation: CUSTOMER_CREATE,
      variables: { input: values },
    })

    return parseCustomerCreateResult(customerCreateResult)
  } catch (error) {
    console.log(error)

    return {
      fieldErrors: {},
      errorCount: 0,
      status: ParseStatus.UNKNOWN_ERROR,
    }
  }
}

interface UserAccessTokenResponse {
  loginUserResult: ParseResults
  user?: UserCookie
}

/**
 * Creates a new user access token from credentials.
 */
export const createUserAccessToken = async (
  shopifyStorefrontClient: ShopifyClient,
  email: string,
  password: string
): Promise<UserAccessTokenResponse> => {
  // Create user token
  const customerAccessTokenCreateResult = await shopifyStorefrontClient.mutate<
    CustomerAccessTokenCreateMutation,
    CustomerAccessTokenCreateMutationVariables
  >({
    mutation: CUSTOMER_ACCESS_TOKEN_CREATE,
    variables: {
      input: {
        email,
        password,
      },
    },
  })
  const loginUserResult = parseCustomerAccessTokenCreateResult(
    customerAccessTokenCreateResult
  )

  if (
    loginUserResult.status !== ParseStatus.OK ||
    loginUserResult.errorCount > 0
  ) {
    return { loginUserResult }
  }

  const token =
    customerAccessTokenCreateResult.data?.customerAccessTokenCreate
      ?.customerAccessToken?.accessToken ?? ''
  const user: UserCookie = {
    isLoggedIn: true,
    email,
    token,
  }

  return {
    loginUserResult,
    user,
  }
}

/**
 * Deletes a user access token.
 */
export const deleteUserAccessToken = async (
  shopifyStorefrontClient: ShopifyClient,
  token: string
) => {
  // Delete user token
  await shopifyStorefrontClient.mutate<
    CustomerAccessTokenDeleteMutation,
    CustomerAccessTokenDeleteMutationVariables
  >({
    mutation: CUSTOMER_ACCESS_TOKEN_DELETE,
    variables: {
      customerAccessToken: token,
    },
  })
}

/**
 * Sends a password recovery email.
 */
export const recoverUserPassword = async (
  shopifyStorefrontClient: ShopifyClient,
  email: string
) => {
  try {
    const customerRecoverResult = await shopifyStorefrontClient.mutate<
      CustomerRecoverMutation,
      CustomerRecoverMutationVariables
    >({
      mutation: CUSTOMER_RECOVER,
      variables: { email },
    })

    return parseCustomerRecoverResult(customerRecoverResult)
  } catch (error) {
    console.log(error)

    return {
      fieldErrors: {},
      errorCount: 0,
      status: ParseStatus.UNKNOWN_ERROR,
    }
  }
}

/**
 * Gets customer orders from Shopify.
 */
export const getShopifyUserOrders = async (
  shopifyStorefrontClient: ShopifyClient,
  userToken: string,
  afterCursor: string | null
) => {
  try {
    const getCustomerOrdersResult = await shopifyStorefrontClient.query<
      GetCustomerOrdersQuery,
      GetCustomerOrdersQueryVariables
    >({
      query: GET_CUSTOMER_ORDERS,
      variables: {
        customerAccessToken: userToken,
        orderLimit: USER_LIST_PAGE_LIMIT,
        afterCursor,
      },
    })

    return getCustomerOrdersResult.data
  } catch (error) {
    console.log(error)

    return { customer: null }
  }
}
