import { useContext, useEffect, useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { HasAccountStrings } from '@data/sanity/queries/types/site'
import { getPageUrl, PageType } from '@lib/routes'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'
import { useUserProductList } from '@lib/user'

import Button from '@components/buttons/button'

interface AccountProductListProps extends HasAccountStrings {
  className?: string
}

const AccountProductList = ({
  accountStrings,
  className,
}: AccountProductListProps) => {
  const strings = useContext(StringsContext)
  const [productPageIndex, setProductPageIndex] = useState(1)
  const [hasMoreProducts, setHasMoreProducts] = useState(false)
  const {
    isLoadingProducts,
    products: allProducts,
    productsPerPage,
  } = useUserProductList()

  const products = allProducts.slice(0, productPageIndex * productsPerPage)
  const productCount = allProducts.length

  useEffect(() => {
    setHasMoreProducts(productPageIndex * productsPerPage < productCount)
  }, [productCount, productPageIndex, productsPerPage])

  return (
    <div className={className}>
      <h4>{accountStrings.accountProductListHeading}</h4>

      {products.length === 0 && !isLoadingProducts && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mt-3"
          blocks={accountStrings.accountProductListEmpty}
          serializers={serializers}
        />
      )}

      {products.length > 0 && (
        <div className="mt-4">
          <div className="py-4 px-5 text-pageText text-sm hidden sm:flex">
            <div className="w-full">
              {accountStrings.accountProductListProduct}
            </div>
          </div>

          {products.map((product) => (
            <div
              key={product.id}
              className="flex flex-col sm:flex-row py-5 px-5 bg-box-bg border-t border-box-border border-opacity-25 text-sm"
            >
              <div className="mb-4 sm:mb-0 sm:w-[80%] sm:pr-4">
                {product.title}
              </div>
              <div className="sm:w-[20%] text-right text-pageText">
                <a href={getPageUrl(PageType.PRODUCT, product.slug)}>
                  {accountStrings.accountProductListViewProduct}
                </a>
              </div>
            </div>
          ))}

          {hasMoreProducts && (
            <div className="mt-4 text-center">
              <Button
                secondary
                small
                disabled={isLoadingProducts}
                className="min-w-[136px]"
                onClick={() => setProductPageIndex((index) => index + 1)}
              >
                {isLoadingProducts
                  ? strings.buttonLoading
                  : strings.buttonLoadMore}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AccountProductList
