import { ButtonHTMLAttributes } from 'react'
import cx from 'classnames'

import Icon from '@components/icon'

const ChevronButton = ({
  className,
  onClick,
  disabled,
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cx(className, { 'opacity-50 !cursor-default': disabled })}
    >
      <span className="inline-flex items-center justify-center rounded-full bg-white w-[50px] h-[50px]">
        <Icon
          id="chevron-button-icon"
          name="ChevronRight"
          viewBox="0 0 28 28"
          className="text-dark block w-[50%]"
        />
      </span>
    </button>
  )
}

export default ChevronButton
