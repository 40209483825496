import cx from 'classnames'
import { deviceType } from 'detect-it'
import {
  KeenSliderHooks,
  KeenSliderInstance,
  useKeenSlider,
  KeenSliderOptions,
} from 'keen-slider/react'
import { ReactNode, useState } from 'react'

import { screens } from '@lib/theme'

import ChevronButton from '@components/buttons/chevron-button'

/**
 * Gets slide count per view from slider options.
 */
export const getSlidesPerView = (
  slider: KeenSliderInstance<unknown, unknown, KeenSliderHooks>
) => {
  const slideOptions = slider.options.slides

  if (
    slideOptions &&
    typeof slideOptions === 'object' &&
    slideOptions.perView &&
    typeof slideOptions.perView === 'number'
  ) {
    return slideOptions.perView
  }
}

interface CarouselProps {
  options: KeenSliderOptions & { selector: string }
  children: ReactNode
  className?: string
}

const Carousel = ({ options, children, className }: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slidesPerView, setSlidesPerView] = useState(1)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    defaultAnimation: { duration: 800 },
    slides: { perView: 1, spacing: 40 },
    drag: deviceType === 'touchOnly',
    breakpoints: {
      [`(min-width: ${screens.sm})`]: {
        slides: { perView: 2, spacing: 40 },
      },
      [`(min-width: ${screens.md})`]: {
        slides: { perView: 3, spacing: 40 },
      },
    },
    created(slider) {
      const newSlidesPerView = getSlidesPerView(slider)

      if (newSlidesPerView) {
        setSlidesPerView(newSlidesPerView)
      }
    },
    optionsChanged(slider) {
      const newSlidesPerView = getSlidesPerView(slider)

      if (newSlidesPerView) {
        setSlidesPerView(newSlidesPerView)
      }
    },
    ...options,
  })

  const handlePrevClick = () => {
    const prevIndex = Math.max(
      currentSlide > slideCount - slidesPerView
        ? slideCount - slidesPerView - 1
        : currentSlide - 1,
      0
    )
    slider.current?.moveToIdx(prevIndex)
    setCurrentSlide(prevIndex)
  }

  const handleNextClick = () => {
    const nextIndex = Math.min(currentSlide + 1, slideCount - slidesPerView)
    slider.current?.moveToIdx(nextIndex)
    setCurrentSlide(nextIndex)
  }

  const slideCount = slider.current?.track?.details?.slides?.length ?? 0

  return (
    <div className={cx('relative', className)}>
      <div
        className="flex relative overflow-hidden will-change-transform touch-action touch-action-pan-y"
        ref={sliderRef}
      >
        {children}
      </div>

      <div className="z-10 absolute inset-y-0 left-[-20px] sm:left-[-25px] flex flex-col justify-center -mt-10">
        <ChevronButton
          onClick={handlePrevClick}
          className="rotate-180"
          disabled={currentSlide === 0}
        />
      </div>

      <div className="z-10 absolute inset-y-0 right-[-20px] sm:right-[-25px] flex flex-col justify-center -mt-10">
        <ChevronButton
          onClick={handleNextClick}
          disabled={currentSlide === slideCount - slidesPerView}
        />
      </div>
    </div>
  )
}

export default Carousel
