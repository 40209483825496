import { useContext, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { HasAccountStrings } from '@data/sanity/queries/types/site'
import { useDeleteAddress } from '@lib/shopify/graphql/customer-address'
import { fadeAnimation } from '@lib/animate'
import { useUser } from '@lib/auth'
import { countryNames, defaultCountryCodes } from '@lib/country'
import { LanguageContext, Locale } from '@lib/language'
import { StringsContext } from '@lib/strings'
import { AddressFormValues } from '@lib/user'

import Button from '@components/buttons/button'
import AddressForm, { AddressFormMode } from './address-form'

interface AccountAddressDetailsProps extends HasAccountStrings {
  className?: string
}

const AccountAddressDetails = ({
  accountStrings,
  className,
}: AccountAddressDetailsProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const [formMode, setFormMode] = useState<AddressFormMode>(
    AddressFormMode.CREATE
  )
  const [addressId, setAddressId] = useState('')
  const [showForm, setShowForm] = useState(false)

  const initialValues: AddressFormValues = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    country: countryNames[Locale.ENGLISH][defaultCountryCodes[locale]],
    zip: '',
    phone: '',
    isDefault: false,
  }
  const [formDefaultValues, setFormDefaultValues] =
    useState<AddressFormValues>(initialValues)

  const { user } = useUser()
  const deleteAddress = useDeleteAddress()

  const token = user?.token
  const addresses = user?.addresses ?? []

  if (!token) {
    return null
  }

  const showCreateForm = () => {
    setFormMode(AddressFormMode.CREATE)
    setFormDefaultValues(initialValues)
    setAddressId('')
    setShowForm(true)
  }

  const showEditForm = (id: string, values: AddressFormValues) => {
    setFormMode(AddressFormMode.EDIT)
    setFormDefaultValues(values)
    setAddressId(id)
    setShowForm(true)
  }

  return (
    <div className={className}>
      <AnimatePresence mode="wait">
        {showForm && (
          <motion.div
            key="form"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <AddressForm
              accountStrings={accountStrings}
              mode={formMode}
              hide={() => setShowForm(false)}
              addressId={addressId}
              defaultValues={formDefaultValues}
            />
          </motion.div>
        )}

        {!showForm && (
          <motion.div
            key="list"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <div className="bg-box-bg border border-box-border p-6 lg:p-8 mb-7 max-w-[400px] mx-auto">
              <h4>{accountStrings.accountAddAddressHeading}</h4>

              <Button
                secondary
                small
                onClick={showCreateForm}
                className="min-w-[150px] mt-5"
              >
                {accountStrings.accountAddAddress}
              </Button>
            </div>

            {addresses.map((address) => (
              <div
                key={address.id}
                className="bg-box-bg border border-box-border p-6 lg:p-8 mb-7 max-w-[400px] mx-auto"
              >
                {address.isDefault && (
                  <h4 className="mb-5">
                    {accountStrings.accountDefaultAddressLabel}
                  </h4>
                )}

                <div>
                  {address.formatted.map((addressLine) => (
                    <div key={addressLine} className="text-sm leading-normal">
                      {addressLine}
                    </div>
                  ))}
                </div>

                <Button
                  secondary
                  small
                  onClick={() => showEditForm(address.id, address.values)}
                  className="min-w-[120px] mt-5"
                >
                  {strings.buttonEdit}
                </Button>
                <button
                  className="mt-5 ml-7 font-semibold text-sm"
                  onClick={() => deleteAddress(address.id, token)}
                >
                  {strings.buttonDelete}
                </button>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AccountAddressDetails
