import BlockContent from '@sanity/block-content-to-react'

import { SanityAccordions } from '@data/sanity/queries/types/blocks'
import { serializers } from '@lib/serializers'

import Accordion from '@components/accordion'

type AccordionsProps = SanityAccordions

const Accordions = ({ items }: AccordionsProps) => {
  return (
    <div className="mb-12 last:mb-0">
      {items.map(({ id, title, content }) => (
        <Accordion key={id} id={id} title={title}>
          <BlockContent
            renderContainerOnSingleChild
            className="rc"
            blocks={content}
            serializers={serializers}
          />
        </Accordion>
      ))}
    </div>
  )
}

export default Accordions
